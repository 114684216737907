<template>
  <div>
    <headers></headers>
    <div class="exam" v-if="dataEnd">
      <!-- 面包屑 -->
      <!-- <div class="breadcrumb w d-flex align-center">
        <div class="breadcrumb-title d-flex align-center cursor">
          <span></span>
          海量题库
          <span></span>
        </div>
        <ul class="breadcrumb-list d-flex align-center">
          <li class="breadcrumb-item cursor">幼儿教师资格</li>
          <li class="breadcrumb-item">></li>
          <li class="breadcrumb-item cursor">第一节教育</li>
        </ul>
      </div> -->
      <!-- 进度条 -->
      <div class="schedule w">
        <div class="schedule-name">{{ name }}</div>
        <div class="schedule-bottom d-flex align-center justify-between">
          <div class="schedule-line">
            <span
              :style="{ width: (tipsNum / tips.length) * 100 + '%' }"
            ></span>
          </div>
          <div class="schedule-num">
            <span>已完成{{ tipsNum }}题</span> / 共{{ tips.length }}题
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content w d-flex justify-between">
        <div class="left">
          <div class="left-content">
            <div
              class="question"
              v-if="dataEnd"
              v-html="listData.questionList[activIndex].dataText"
            ></div>
            <div
              class="question"
              v-if="dataEnd"
              v-html="listData.questionList[activIndex].question"
            ></div>

            <div class="options" v-if="dataEnd && !isdone">
              <div class="options-title">请作答:</div>
              <!-- 单选 -->
              <div
                class="radio"
                v-if="
                  listData.questionList[activIndex].type.ansInputType ===
                  'radio'
                "
              >
                <ul
                  class="radio-list d-flex"
                  v-for="(ra, ida) in options"
                  :key="ida"
                >
                  <li v-if="options.length > 1" class="radio-title">
                    {{ ida + 1 }}、
                  </li>
                  <li
                    class="radio-item d-flex align-center justify-center cursor"
                    v-for="(ras, idas) in ra"
                    :key="idas"
                    :class="{ radio_active: ras.checked }"
                    @click="selectRadio(ida, ras.value)"
                  >
                    {{ ras.name }}
                  </li>
                </ul>
              </div>
              <!-- 多选 -->
              <div
                class="checkbox"
                v-if="
                  listData.questionList[activIndex].type.ansInputType ===
                  'checkbox'
                "
              >
                <ul
                  class="radio-list d-flex"
                  v-for="(ra, ida) in options"
                  :key="ida"
                >
                  <li
                    class="radio-item d-flex align-center justify-center cursor"
                    v-for="(ras, idas) in ra"
                    :key="idas"
                    :class="{ radio_active: ras.checked }"
                    @click="selectRadio(ida, ras.value)"
                  >
                    {{ ras.name }}
                  </li>
                </ul>
              </div>
              <!-- 简答题 -->
              <div
                class="options-text"
                v-if="
                  listData.questionList[activIndex].type.ansInputType === 'text'
                "
              >
                <textarea
                  name=""
                  v-model="value"
                  placeholder="此处作答，请输入您的答案"
                  @input="bindTextAreaBlur()"
                ></textarea>
              </div>
            </div>
            <div
              class="collect d-flex align-center cursor"
              @click="
                collectBtn(listData.questionList[activIndex].isCollection)
              "
            >
              <img
                v-if="listData.questionList[activIndex].isCollection == 0"
                src="../../assets/img/ticollect.png"
                alt=""
              />
              <img
                v-if="listData.questionList[activIndex].isCollection == 1"
                src="../../assets/img/ticollect_b.png"
                alt=""
              />
              收藏
            </div>
            <div
              class="question"
              style="margin-top: 20px"
              v-if="isAnalytical"
              v-html="listData.questionList[activIndex].explains"
            ></div>
            <div
              class="question"
              style="margin-top: 20px"
              v-if="isdone"
              v-html="listData.questionList[activIndex].explains"
            ></div>
          </div>
          <div
            class="bottom-btn d-flex align-center justify-between"
            v-if="dataEnd"
          >
            <div
              class="btn-item-l d-flex align-center cursor"
              @click="bindPre"
              :class="{ botton_btn_active: activIndex == 0 }"
            >
              <img
                v-show="activIndex == 0"
                src="../../assets/img/next_w.png"
                alt=""
              />
              <img
                v-show="activIndex != 0"
                src="../../assets/img/next_b.png"
                alt=""
              />
              上一题
            </div>
            <div
              class="btn-item-c d-flex align-center cursor"
              @click="analytical"
              v-if="!isdone"
            >
              <img src="../../assets/img/see.png" alt="" />
              查看解析
            </div>
            <div
              class="btn-item-r d-flex align-center cursor"
              @click="bindNext"
              :class="{
                botton_btn_active:
                  listData.questionList.length - 1 == activIndex,
              }"
            >
              下一题
              <img
                v-show="listData.questionList.length - 1 == activIndex"
                src="../../assets/img/next_w.png"
                alt=""
              />
              <img
                v-show="listData.questionList.length - 1 != activIndex"
                src="../../assets/img/next_b.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right">
          <!-- <div class="operate d-flex align-center justify-between">
            <div class="operate-item cursor">
              <img class="item-img" src="../../assets/img/go_back.png" alt="" />
              <div class="item-txt">返回</div>
            </div>
            <div class="operate-item cursor">
              <img class="item-img" src="../../assets/img/pause.png" alt="" />
              <div class="item-txt">暂停</div>
            </div>
          </div> -->
          <!-- <div class="time d-flex align-center justify-between">
            <div class="time-left d-flex align-center">
              <img
                class="time-img"
                src="../../assets/img/exam_time.png"
                alt=""
              />
              <div class="time-title">用时</div>
            </div>
            <div class="time-right">00.07</div>
          </div> -->
          <div class="card">
            <div class="card-name d-flex align-center justify-between">
              <div class="card-left d-flex align-center">
                <span> </span>
                答题卡 <span>{{ tipsNum }}</span> /{{ tips.length }}
              </div>
              <div
                class="card-btn d-flex align-center cursor"
                @click="undoRedo"
              >
                <img
                  class="card-btn-img"
                  src="../../assets/img/card_btn.png"
                  alt=""
                />
                重做
              </div>
            </div>

            <div class="card-content">
              <!-- <div class="card-title d-flex align-center justify-between">
                <div class="card-names d-flex align-center">
                  <span></span>
                  试题选择
                </div>
                <div class="card-btn d-flex align-center cursor">
                  <img
                    class="card-btn-img"
                    src="../../assets/img/card_btn.png"
                    alt=""
                  />
                  重做
                </div>
              </div> -->
              <ul class="card-list d-flex flex-wrap">
                <li
                  class="card-item d-flex align-center justify-center cursor"
                  v-for="(item, index) in tips"
                  :key="index"
                  :class="{ card_item_active: item.select }"
                  @click="selectTopic(index)"
                >
                  {{ index + 1 }}
                </li>
              </ul>
            </div>
          </div>
          <div v-if="!isdone">
            <div
              class="right-btn d-flex align-center justify-center cursor"
              @click="progress"
              v-if="tipsNum != tips.length"
            >
              保存进度 下次继续
            </div>
            <div
              class="right-btn-b d-flex align-center justify-center cursor"
              @click="submitBtn"
              v-if="tipsNum == tips.length"
            >
              作答完毕 点击交卷
            </div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollerHeight: "30%",
      dataEnd: false, //数据请求完成与否
      subjectId: "", //专业id
      examId: "", //测试id
      examtype: "", //测试类型
      ansInputType: "",
      source: "", //试题来源
      listData: "", //数据列表
      activIndex: 0, //当前试题
      value: "", //当前文本题的答案
      options: [], //当前选择题的选项和答案
      tips: [],
      tipsNum: 0,
      name: "",
      isAnalytical: false,
      isdone: false
    };
  },
  mounted() {
    this.subjectId = this.$route.query.subjectId;
    this.examId = this.$route.query.examId;
    this.examtype = this.$route.query.examtype;
    this.name = this.$route.query.name;
    this.ansInputType = this.$route.query.ansInputType;
    this.source = this.$route.query.source;
    if (this.source) {
      this.exercise();
    } else {
      this.getInit();
    }
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.cancel, false);
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.cancel, false);
  },
  methods: {
    cancel() {
      let listData = this.listData;
      for (var i in listData.questionList) {
        var options = window.localStorage.removeItem(
          "option-" + listData.questionList[i].id + listData.examHistoryId
        );
      }
      console.log("返回");
      sessionStorage.clear();
      window.history.back();
      history.pushState(null, null, document.URL);
    },
    // 查看解析
    analytical() {
      this.isAnalytical = !this.isAnalytical;
    },
    // 收藏
    collectBtn(isCollect) {
      let _this = this;
      var activIndex = this.activIndex; // 当前试题
      var listData = this.listData; // 源数据
      var questionNum = listData.questionList[activIndex].id;
      let status;
      if (isCollect == 0) {
        status = 1;
      } else {
        status = 0;
      }
      _this.collect(questionNum, status)
    },

    // 保存进度
    progress() {
      this.funSubmit(2);
    },
    // 交卷
    submitBtn() {
      this.funSubmit(1);
    },
    // 答题卡选择
    selectTopic(index) {
      let _this = this;
      _this.isAnalytical = false;
      _this.activIndex = index;
      var questionList = _this.listData.questionList[index]; //下一题对象
      var options = _this.setOption(
        questionList.type.questionNums,
        questionList.type.ansInputNums
      );
      //这里要把value的值显示在页面上
      //如果是选择题返回一个option,文本题返回一个字符串
      options = _this.setOptionChecked(options, questionList);
      //这里要把文本答案赋值给value用来显示在页面上
      var value = "";
      if (questionList.type.ansInputType == "text") {
        var option = JSON.parse(
          window.localStorage.getItem(
            "option-" + questionList.id + _this.listData.examHistoryId
          )
        );
        if (option && option.length > 0) {
          console.log(option[0].answer);
          value = option[0].answer;
        }
      }
      _this.value = value; //重置选择值
      _this.options = options;
    },
    // 初始数据
    async getInit(params) {
      let _this = this;
      let data = {
        subjectId: _this.subjectId,
        examId: _this.examId,
        examtype: _this.examtype
      }
      const res = await this.$ajaxRequest('UPLOAD', 'begin', data)
      if (res.data.isHand == 1) {
        let list = res.data.userExamHistoryDetailsList;
        for (let i in list) {
          let options = [];
          let option = {
            qid: 0,
            answer: list[i].userAnswer
          };
          if (list[i].qdbQuestions.type.questionNums > 1) {
            var array = list[i].userAnswer.split(",");
            for (let j in array) {
              let option = {
                qid: j,
                answer: array[j]
              };
              options.push(option);
            }
          }

          // var array = list[i].userAnswer.split(",");
          console.log(array);
          options.push(option);
          window.localStorage.setItem(
            "option-" + list[i].qdbQuestions.id + res.data.examHistoryId,
            JSON.stringify(options)
          );
        }
      }
      if (res.data.isHand == 2) {
        _this.isdone = true;
      }
      _this.listData = res.data;
      var questionList = _this.listData.questionList[0];
      console.log(questionList);
      //对初始数据做一下格式化
      var options = _this.setOption(
        questionList.type.questionNums,
        questionList.type.ansInputNums
      );
      options = _this.setOptionChecked(options, questionList);
      //这里要把value的值显示在页面上
      var value = "";
      if (questionList.type.ansInputType == "text") {
        var option = JSON.parse(
          window.localStorage.getItem(
            "option-" + questionList.id + _this.listData.examHistoryId
          )
        );
        if (option && option.length > 0) {
          // console.log(option[0].answer);
          value = option[0].answer;
        }
      }

      // _this.activIndex = _this.activIndex;
      _this.value = value; //重置选择值
      _this.options = options;
      // options = _this.setOptionChecked(options, questionList);
      _this.dataEnd = true;
      console.log(options);
      _this.judgeDecision();
    },
    // getInit() {
    //   let _this = this;
    //   _this.$api
    //     .begin({
    //       subjectId: _this.subjectId,
    //       examId: _this.examId,
    //       examtype: _this.examtype
    //     })
    //     .then(res => {
    //       console.log(res);
    //       if (res.data.isHand == 1) {
    //         let list = res.data.userExamHistoryDetailsList;
    //         for (let i in list) {
    //           let options = [];
    //           let option = {
    //             qid: 0,
    //             answer: list[i].userAnswer
    //           };
    //           if (list[i].qdbQuestions.type.questionNums > 1) {
    //             var array = list[i].userAnswer.split(",");
    //             for (let j in array) {
    //               let option = {
    //                 qid: j,
    //                 answer: array[j]
    //               };
    //               options.push(option);
    //             }
    //           }

    //           // var array = list[i].userAnswer.split(",");
    //           console.log(array);
    //           options.push(option);
    //           window.localStorage.setItem(
    //             "option-" + list[i].qdbQuestions.id + res.data.examHistoryId,
    //             JSON.stringify(options)
    //           );
    //         }
    //       }
    //       if (res.data.isHand == 2) {
    //         _this.isdone = true;
    //       }
    //       _this.listData = res.data;
    //       var questionList = _this.listData.questionList[0];
    //       console.log(questionList);
    //       //对初始数据做一下格式化
    //       var options = _this.setOption(
    //         questionList.type.questionNums,
    //         questionList.type.ansInputNums
    //       );
    //       options = _this.setOptionChecked(options, questionList);
    //       //这里要把value的值显示在页面上
    //       var value = "";
    //       if (questionList.type.ansInputType == "text") {
    //         var option = JSON.parse(
    //           window.localStorage.getItem(
    //             "option-" + questionList.id + _this.listData.examHistoryId
    //           )
    //         );
    //         if (option && option.length > 0) {
    //           // console.log(option[0].answer);
    //           value = option[0].answer;
    //         }
    //       }

    //       // _this.activIndex = _this.activIndex;
    //       _this.value = value; //重置选择值
    //       _this.options = options;
    //       // options = _this.setOptionChecked(options, questionList);
    //       _this.dataEnd = true;
    //       console.log(options);
    //       _this.judgeDecision();
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    // 初始数据
    async exercise(params) {
      let _this = this;
      let data = {
        subjectId: _this.subjectId,
        examtype: _this.examtype,
        ansInputType: _this.ansInputType
      }
      const res = await this.$ajaxRequest('get', 'exercise', data)
      _this.listData = res.data;
      if (!_this.listData.questionList) {
        this.$alert("此练习暂无试提", "提示", {
          confirmButtonText: "确定",
          callback: action => {
            this.$router.go(-1);
          }
        });
      }
      var questionList = _this.listData.questionList[0];
      console.log(questionList);
      //对初始数据做一下格式化
      var options = _this.setOption(
        questionList.type.questionNums,
        questionList.type.ansInputNums
      );
      options = _this.setOptionChecked(options, questionList);
      //这里要把value的值显示在页面上
      var value = "";
      if (questionList.type.ansInputType == "text") {
        var option = JSON.parse(
          window.localStorage.getItem(
            "option-" + questionList.id + _this.listData.examHistoryId
          )
        );
        if (option && option.length > 0) {
          // console.log(option[0].answer);
          value = option[0].answer;
        }
      }

      // _this.activIndex = _this.activIndex;
      _this.value = value; //重置选择值
      _this.options = options;
      // options = _this.setOptionChecked(options, questionList);
      _this.dataEnd = true;
      _this.isdone = true;
      console.log(options);
      _this.judgeDecision();
    },
    // exercise() {
    //   let _this = this;
    //   let data;
    //   _this.$api
    //     .exercise({
    //       subjectId: _this.subjectId,
    //       examtype: _this.examtype,
    //       ansInputType: _this.ansInputType
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.listData = res.data;
    //       if (!_this.listData.questionList) {
    //         this.$alert("此练习暂无试提", "提示", {
    //           confirmButtonText: "确定",
    //           callback: action => {
    //             this.$router.go(-1);
    //           }
    //         });
    //       }
    //       var questionList = _this.listData.questionList[0];
    //       console.log(questionList);
    //       //对初始数据做一下格式化
    //       var options = _this.setOption(
    //         questionList.type.questionNums,
    //         questionList.type.ansInputNums
    //       );
    //       options = _this.setOptionChecked(options, questionList);
    //       //这里要把value的值显示在页面上
    //       var value = "";
    //       if (questionList.type.ansInputType == "text") {
    //         var option = JSON.parse(
    //           window.localStorage.getItem(
    //             "option-" + questionList.id + _this.listData.examHistoryId
    //           )
    //         );
    //         if (option && option.length > 0) {
    //           // console.log(option[0].answer);
    //           value = option[0].answer;
    //         }
    //       }

    //       // _this.activIndex = _this.activIndex;
    //       _this.value = value; //重置选择值
    //       _this.options = options;
    //       // options = _this.setOptionChecked(options, questionList);
    //       _this.dataEnd = true;
    //       _this.isdone = true;
    //       console.log(options);
    //       _this.judgeDecision();
    //     })
    //     .catch(err => { });
    // },
    // 判断做题与否
    judgeDecision() {
      let _this = this;
      let tips = [];
      let listData = _this.listData;
      for (let i in _this.listData.questionList) {
        let item;
        var options = JSON.parse(
          window.localStorage.getItem(
            "option-" + listData.questionList[i].id + listData.examHistoryId
          )
        );
        if (!(options && options.length > 0)) {
          item = {
            item: i,
            select: false
          };
        } else if (listData.questionList[i].type.questionNums > 1) {
          for (var j = 0; j < listData.questionList[i].type.questionNums; j++) {
            if (!_this.isAnswered(j, options)) {
              item = {
                item: i,
                select: false
              };
            } else {
              item = {
                item: i,
                select: true
              };
            }
          }
        } else if (options[0].answer == "") {
          item = {
            item: i,
            select: false
          };
        } else {
          item = {
            item: i,
            select: true
          };
        }
        tips.push(item);
      }
      let tipsNum = 0;
      for (let i in tips) {
        if (tips[i].select) {
          tipsNum++;
        }
      }
      console.log(tips);
      console.log(tipsNum);
      _this.tips = tips;
      _this.tipsNum = tipsNum;
    },
    //  判断题目是否已经回答，并且有答案
    isAnswered(qid, array) {
      if (array && array.length > 0) {
        for (var i in array) {
          if (array[i].qid == qid && array[i].answer.length > 0) {
            return true;
          }
        }
      }
      return false;
    },
    //下一题
    bindNext() {
      // console.log(this.activIndex);
      // this.activIndex = this.activIndex + 1;

      var _this = this;
      _this.isAnalytical = false;
      var activIndex = _this.activIndex; //当前题次
      // 切换至下一题
      if (activIndex == _this.listData.questionList.length - 1) {
        //是最后一题，提交所有答案
        // _this.funSubmit();
        console.log("最后一题");
      } else {
        var questionList = _this.listData.questionList[activIndex + 1]; //下一题对象
        //不是最后一题，题目索引加1
        //可以写一个方法创建一个数组，长度为选项数量
        var options = _this.setOption(
          questionList.type.questionNums,
          questionList.type.ansInputNums
        );
        //这里要把value的值显示在页面上
        //如果是选择题返回一个option,文本题返回一个字符串
        options = _this.setOptionChecked(options, questionList);
        //这里要把文本答案赋值给value用来显示在页面上
        var value = "";
        if (questionList.type.ansInputType == "text") {
          var option = JSON.parse(
            window.localStorage.getItem(
              "option-" + questionList.id + _this.listData.examHistoryId
            )
          );
          if (option && option.length > 0) {
            console.log(option[0].answer);
            value = option[0].answer;
          }
        }
        _this.activIndex = activIndex + 1;
        _this.value = value; //重置选择值
        _this.options = options;
        console.log(options[0]);
        _this.judgeDecision();
      }
    },
    // 上一题
    bindPre(e) {
      var _this = this;
      _this.isAnalytical = false;
      var activIndex = _this.activIndex;
      if (activIndex > 0) {
        var questionList = _this.listData.questionList[activIndex - 1];
        //可以写一个方法创建一个数组，长度为选项数量
        var options = _this.setOption(
          questionList.type.questionNums,
          questionList.type.ansInputNums
        );
        options = _this.setOptionChecked(options, questionList);
        //这里要把value的值显示在页面上
        var value = "";
        if (questionList.type.ansInputType == "text") {
          var option = JSON.parse(
            window.localStorage.getItem(
              "option-" + questionList.id + _this.listData.examHistoryId
            )
          );
          if (option && option.length > 0) {
            console.log(option[0].answer);
            value = option[0].answer;
          }
        }
        _this.activIndex = activIndex - 1;
        _this.value = value; //重置选择值
        _this.options = options;
        console.log(options);
        _this.judgeDecision();
      }
    },
    //单选选择
    selectRadio(index, value) {
      var activIndex = this.activIndex; // 当前试题
      var listData = this.listData; // 源数据
      var questionNum = listData.questionList[activIndex].type.questionNums; //当前试题题目数量
      var options = this.options;
      if (listData.questionList[activIndex].type.ansInputType == "radio") {
        for (let i in options[index]) {
          options[index][i].checked = false;
          if (options[index][i].value == value) {
            options[index][i].checked = true;
          }
        }
        this.options = options;
      }
      //如果题目是CheckBox类型则将value中的数组转化为字符串
      if (listData.questionList[activIndex].type.ansInputType == "checkbox") {
        let selectValue = [];
        for (let i in options[index]) {
          if (options[index][i].value == value) {
            options[index][i].checked = !options[index][i].checked;
          }
          if (options[index][i].checked) {
            selectValue.push(options[index][i].value);
          }
        }
        console.log(selectValue);
        value = selectValue.sort(); //排序
        value = selectValue.join(""); //转化成字符串
      }

      if (questionNum == 1) {
        //只有单题
        index = 0;
      }
      //将事件中的值写入缓存中
      this.setOptions(index, value);
      this.judgeDecision();
    },
    // 输入框
    bindTextAreaBlur() {
      // console.log()
      var index = 0;
      // 输入框中的值
      var value = this.value;
      // 将事件中的值写入缓存中
      this.setOptions(index, value);
      this.judgeDecision();
    },
    //生成选项
    setOption(questionNums, ansInputNums) {
      var options = [];
      var option = [];
      for (var j = 0; j < questionNums; j++) {
        for (var i = 0; i < ansInputNums; i++) {
          var opt = {
            name: String.fromCharCode(65 + i),
            value: String.fromCharCode(65 + i),
            checked: false
          };
          option.push(opt);
        }
        options.push(option);
        option = [];
      }
      return options;
    },
    // 设置option的checked值
    setOptionChecked(options, questionList) {
      //传过来一个答案，然后将答案对应的checked值设置为true
      //在缓存中查找是否存在有这个答案
      var option = JSON.parse(
        window.localStorage.getItem(
          "option-" + questionList.id + this.listData.examHistoryId
        )
      );
      if (option && option.length > 0) {
        for (var k = 0; k < questionList.type.questionNums; k++) {
          //题目数量
          for (var i in option) {
            //遍历缓存中的答案
            var opt = options[k];
            for (var j in opt) {
              //遍历选项
              if (option[i].qid == k) {
                if (option[i].answer == opt[j].value) {
                  opt[j].checked = true;
                }
                if (questionList.type.ansInputType == "checkbox") {
                  //如果是checkbox类型的数据用以下算法
                  // opt = this.setCheckboxOption(opt, option[k].answer);
                }
              }
            }
            options[k] = opt;
          }
        }
      }
      return options;
    },
    //将事件中的值写入缓存中
    setOptions(index, value) {
      var _this = this;
      var activIndex = this.activIndex; // 当前试题
      var listData = this.listData; // 源数据
      //从缓存中查找是否已经存在名为"option-" + listData.questionList[activIndex].id的答案对象
      var options = JSON.parse(
        window.localStorage.getItem(
          "option-" +
          listData.questionList[activIndex].id +
          listData.examHistoryId
        )
      ); //{'idx': 0, 'answer' : 'A'}
      console.log(options);
      //不存在则设置options为空
      if (!options) {
        options = [];
      }
      //判断option对象中的子选项是否存在
      if (_this.exists(options, index)) {
        //如果存在则设置其值为当前值
        for (var i in options) {
          if (options[i].qid == index) {
            options[i].answer = value;
          }
        }
        //回写缓存
        window.localStorage.setItem(
          "option-" +
          listData.questionList[activIndex].id +
          listData.examHistoryId,
          JSON.stringify(options)
        );
      } else {
        //option对象中的子选项不存在则创建
        var option = {
          qid: index,
          answer: value
        };
        //添加到options中
        console.log(options);
        options.push(option);
        //回写缓存
        window.localStorage.setItem(
          "option-" +
          listData.questionList[activIndex].id +
          listData.examHistoryId,
          JSON.stringify(options)
        );
      }
    },
    //  判断缓存中子选项是否存在
    exists(options, index) {
      if (options && options.length > 0) {
        for (var i in options) {
          if (options[i].qid == index) {
            return true;
          }
        }
      }
      return false;
    },
    // 提交答案
    funSubmit(isHand) {
      var _this = this;
      var activIndex = _this.activIndex;
      var listData = _this.listData;
      console.log(listData);
      //在这里把存在缓存里的数据拿出来并且提交
      var answers = [];
      //  将当前题的用户答案保存到answers中
      for (var i in listData.questionList) {
        var options = JSON.parse(
          window.localStorage.getItem(
            "option-" + listData.questionList[i].id + listData.examHistoryId
          )
        );
        if (options && options.length > 0) {
          // 当前提已作答
          var answer = "";
          for (var j in options) {
            if (options[j].qid == 0) {
              answer = options[j].answer;
            }
          }
          //按顺序提交
          if (listData.questionList[i].type.questionNums > 1) {
            let array = [];
            for (
              var k = 1;
              k < listData.questionList[i].type.questionNums;
              k++
            ) {
              array.push("");
              for (var l in options) {
                // console.log(k, options[l].qid)
                // if (options[l]) {
                //   if (k == options[l].qid) {
                //     answer = answer + "," + options[l].answer;
                //   }
                // } else {
                //   answer = answer + "," + "0";
                // }
                // if (k == options[l].qid) {
                //   answer = answer + "," + options[l].answer;
                // }
                if (k == options[l].qid) {
                  // answer = answer + "," + options[l].answer;
                  array.splice(options[l].qid, 0, options[l].answer);
                }
              }
            }
            answer = array.join(",");
            console.log(answer);
          }

          //保存答案到answers数组中
          answers.push({
            qid: _this.listData.questionList[i].id,
            answer: answer
          });
        } else {
          // 当前提未作答，并且是非选择题
          answers.push({
            qid: _this.listData.questionList[i].id,
            answer: ""
          });
        }
      }
      console.log(answers);
      _this.finishPc(answers,isHand,listData)
      // _this.$api
      //   .finishPc({
      //     subjectId: _this.subjectId,
      //     examHistoryId: listData.examHistoryId,
      //     answers: JSON.stringify(answers),
      //     isHand: isHand
      //   })
      //   .then(res => {
      //     console.log(res);
      //     for (var i in listData.questionList) {
      //       var options = window.localStorage.removeItem(
      //         "option-" + listData.questionList[i].id + listData.examHistoryId
      //       );
      //     }
      //     this.$message({
      //       message: "操作成功",
      //       type: "success"
      //     });
      //     _this.$router.replace("question");
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    // 提交
    async finishPc(answers,isHand,listData) {
      let _this = this;
      let data = {
        subjectId: _this.subjectId,
        examHistoryId: listData.examHistoryId,
        answers: JSON.stringify(answers),
        isHand: isHand
      }
      const res = await this.$ajaxRequest('UPLOAD', 'finishPc', data)
      for (var i in listData.questionList) {
        var options = window.localStorage.removeItem(
          "option-" + listData.questionList[i].id + listData.examHistoryId
        );
      }
      this.$message({
        message: "操作成功",
        type: "success"
      });
      _this.$router.replace("question");
    },
    // 重做
    undoRedo() {
      let _this = this;
      var listData = _this.listData;
      this.$confirm("aaaaa", "重做题目", {
        showClose: false,
        confirmButtonText: "确定",
        type: "warning"
      }).then(res => {
        for (var i in listData.questionList) {
          var options = window.localStorage.removeItem(
            "option-" + listData.questionList[i].id + listData.examHistoryId
          );
        }
        _this.activIndex = 0;
        _this.judgeDecision();
        this.$message({
          message: "操作成功",
          type: "success"
        });
      });
    },
    // 收藏题目
    async collect(questionNum, status) {
      let _this = this;
      let data = {
        questionsId: questionNum,
        statu: status,
        subject: _this.subjectId,
        examtype: _this.examtype
      }
      const res = await this.$ajaxRequest('get', 'collect', data)
      if (_this.source) {
        _this.exercise();
      } else {
        _this.getInit();
      }
    },
  }

};
</script>

<style lang="less" scoped>
.radio_active {
  background: #0a82ff !important;
  color: #ffffff !important;
  border-color: #0a82ff !important;
}
.botton_btn_active {
  color: #999999 !important;
  cursor: not-allowed;
}
.card_item_active {
  background: #0a82ff !important;
  color: #ffffff !important;
}
.exam {
  position: relative;
}
// 面包屑
.breadcrumb {
  margin-top: 29px;
  .breadcrumb-title {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    // padding-left: 15px;
    // box-sizing: border-box;
    // border-left: 4px solid #5f2eff;
    span:first-child {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 11px;
    }
    span:last-child {
      width: 1px;
      height: 10px;
      background: #cccccc;
      border-radius: 1px;
      margin-left: 12px;
    }
  }
  .breadcrumb-item {
    font-size: 14px;
    color: #888888;
    margin-left: 12px;
  }
}
// 进度条
.schedule {
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  margin-top: 39px;
  padding: 29px 20px 20px 20px;
  box-sizing: border-box;
  .schedule-name {
    font-size: 24px;
    color: #222222;
  }
  .schedule-bottom {
    margin-top: 16px;
    .schedule-line {
      width: 980px;
      height: 10px;
      background: #f6f3ff;
      border-radius: 5px;
      span {
        height: 9px;
        background: #5f2eff;
        border-radius: 5px;
        display: block;
      }
    }
    .schedule-num {
      color: #222222;
      font-size: 14px;
      span {
        color: #5f2eff;
        font-size: 14px;
      }
    }
  }
}
// 内容
.content {
  margin-top: 17px;
  // 左边
  .left {
    width: 920px;
    .left-content {
      min-height: 490px;
      padding: 46px 31px;
      box-sizing: border-box;
      background: #ffffff;
      .question {
        font-size: 14px !important;
        color: #444444 !important;
      }
      .options {
        margin-top: 20px;
        .options-title {
          font-weight: bold;
          font-size: 20px;
        }
        .radio {
          margin-bottom: 20px;
          margin-left: 75px;
          .radio-num {
            width: 30px;
            font-size: 14px;
          }
          .radio-list {
            margin-top: 10px;
          }
          .radio-title {
            width: 18px;
            margin-right: 10px;
            line-height: 26px;
          }
          .radio-item {
            width: 26px;
            height: 26px;
            border: 1px solid #bbbbbb;
            border-radius: 50%;
            font-size: 9px;
            font-weight: bold;
            color: #bbbbbb;
            margin-right: 30px;
          }
        }
        .checkbox {
          margin-bottom: 10px;
          margin-left: 50px;
          .radio-num {
            width: 30px;
            font-size: 14px;
          }
          .radio-list {
            margin-top: 10px;
          }
          .radio-item {
            width: 18px;
            height: 18px;
            border: 1px solid #bbbbbb;
            border-radius: 50%;
            font-size: 9px;
            font-weight: bold;
            color: #bbbbbb;
            margin-right: 10px;
          }
        }
        .options-text {
          textarea {
            width: 820px;
            height: 186px;
            background: #f9f9f9;
            border: 1px solid #cccccc;
            border-radius: 6px;
            resize: none;
            padding: 13px 15px;
            box-sizing: border-box;
            margin-top: 15px;
          }
        }
      }
      .collect {
        font-size: 14px;
        color: #666666;
        margin-top: 50px;
        img {
          width: 22px;
          height: 20px;
          margin-right: 10px;
          margin-left: auto;
        }
      }
    }

    .bottom-btn {
      height: 88px;
      width: 920px;
      background: #ffffff;
      position: absolute;
      bottom: -64px;
      padding: 0 101px;
      box-sizing: border-box;
      box-shadow: 0px 0px 13px 0px rgba(180, 180, 180, 0.15);
      border-radius: 14px;
      .btn-item-l {
        font-size: 20px;
        color: #333333;
        img {
          width: 9px;
          height: 18px;
          margin-right: 10px;
        }
      }
      .btn-item-c {
        font-size: 20px;
        color: #333333;
        img {
          width: 22px;
          height: 18px;
          margin-right: 10px;
        }
      }
      .btn-item-r {
        font-size: 20px;
        color: #333333;
        img {
          width: 9px;
          height: 18px;
          margin-left: 10px;
          transform: rotate(180deg);
        }
      }
    }
  }
  // 右边
  .right {
    width: 265px;
    .operate {
      width: 265px;
      height: 86px;
      background: #ffffff;
      border-radius: 10px;
      padding: 0 70px;
      box-sizing: border-box;
      .item-img {
        width: 27px;
        height: 27px;
        margin-bottom: 11px;
      }
      .item-txt {
        font-size: 14px;
        color: #666666;
      }
      .operate-item:first-child {
        .item-img {
          width: 27px;
          height: 25px;
          margin-bottom: 11px;
        }
      }
    }
    .time {
      width: 265px;
      height: 67px;
      background: #ffffff;
      border-radius: 10px;
      padding: 0 30px;
      box-sizing: border-box;
      margin-top: 15px;
      .time-left {
        .time-img {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
        .time-title {
          font-size: 18px;
          color: #999999;
        }
      }
      .time-right {
        width: 122px;
        font-size: 24px;
        font-weight: 500;
        color: #f53444;
        text-align: center;
      }
    }
    .card {
      width: 265px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(180, 180, 180, 0.08);
      border-radius: 10px;
      padding-bottom: 17px;
      box-sizing: border-box;
      .card-name {
        width: 100%;
        font-size: 16px;
        color: #333333;
        padding-top: 16px;
        box-sizing: border-box;
        // margin-bottom: 39px;
        .card-left {
          font-size: 16px;
          color: #333333;
          box-sizing: border-box;
          span:first-child {
            width: 4px;
            height: 17px;
            background: #5f2eff;
            border-radius: 2px;
            display: inline-block;
            margin-left: 19px;
            margin-right: 11px;
          }
          span:last-child {
            color: #5f2eff;
            font-size: 16px;
          }
        }
        .card-btn {
          font-size: 13px;
          color: #666666;
          margin-right: 22px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 7px;
          }
        }
      }
      .card-content {
        height: 300px;
        padding-right: 44px;
        box-sizing: border-box;
        overflow-y: scroll;
        margin-top: 20px;
        border-top: 1px solid #eee;
        .card-names {
          font-size: 13px;
          color: #666666;
          span {
            width: 2px;
            height: 13px;
            background: #5f2eff;
            border-radius: 1px;
            display: inline-block;
            margin-right: 17px;
          }
        }
        .card-btn {
          font-size: 13px;
          color: #666666;
          img {
            width: 14px;
            height: 14px;
            margin-right: 7px;
          }
        }
        .card-list {
          width: 100%;
          margin-left: 19px;
          margin-top: 19px;
          margin-bottom: 20px;
          .card-item {
            width: 28px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 7px;
            color: #a8a8a8;
            font-size: 13px;
            margin-right: 13px;
            margin-bottom: 10px;
            &:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }
    }
    .right-btn {
      width: 225px;
      height: 47px;
      background: #5f2eff;
      border-radius: 24px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      margin-left: 15px;
      margin-top: 30px;
    }
    .right-btn-b {
      width: 225px;
      height: 47px;
      border: 1px solid#5F2EFF;
      border-radius: 24px;
      font-size: 14px;
      font-weight: bold;
      color: #5f2eff;
      margin-left: 15px;
      margin-top: 30px;
    }
  }
}
</style>
